h1, h2, h3, h4, h5, h6 {
  color: $color-background-dark;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  letter-spacing: -0.04em;
}

h1 {
  font-size: 56px;
  line-height: 64px;
  margin: 0 0 24px;
}

h2 {
  font-size: 40px;
  line-height: 48px;
  margin: 20px 0;
}

h3 {
  font-size: 36px;
  line-height: 42px;
  margin: 15px 0;
}

h4 {
  font-size: 30px;
  line-height: 36px;
  margin: 12px 0;
}

h5 {
  font-size: 24px;
  letter-spacing: -0.72px;
  line-height: 30px;
  margin: 8px 0 12px;
}

h6 {
  font-size: 18px;
  letter-spacing: -0.36px;
  line-height: 24px;
  margin: 0 0 4px;
}
