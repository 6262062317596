.landing {
  @include flexCenter;
  background: $color-background;
  flex-direction: column;
  min-height: 800px;
  height: 100vh;
  width: 100%;

  .box {
    background-color: $color-white;
    border-radius: $radius-box;
    box-shadow: $shadow-box;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 150px;
    max-width: 450px;
    padding: 50px;

    &--password {
      margin-top: 100px;
      max-width: 400px;
    }

    .logo {
      @include flexCenter;
      margin-bottom: 30px;

      &__img {
        width: 200px;
      }
    }

    &__heading {
      margin: 0;
      margin-bottom: 30px;
      text-align: center;
    }

    &__buttons {
      display: flex;
      margin-top: 20px;
    }

    &__forgot {
      font-size: 14px;
      margin-right: 20px;
    }

    &__login {
      min-width: 150px;
      width: 100%;
    }
  }

  &__header {
    align-items: center;
    background-color: $color-white;
    box-shadow: $shadow-header;
    display: flex;
    height: $landing-header-height;
    padding: 10px;
    width: 100%;

    .logo {
      @include flexCenter;
      margin-right: 50px;

      &__img {
        height: $landing-header-height - 20;
      }
    }

    .username {
      flex-grow: 1;
    }

    .btn {
      margin-left: 20px;
    }
  }

  &__body {
    height: 100%;
    overflow: auto;
    padding: 100px;
    width: 100%;
  }

  .apps {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin: 0 auto;
    max-width: 1200px;

    .app {
      align-items: center;
      border-radius: $radius-box;
      background-color: rgba($color: $color-background-dark, $alpha: 0.1);
      box-shadow: inset 0 6px 16px rgba($color: $color-background-dark, $alpha: 0.1);
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
      text-decoration: none !important;
      transition: background-color 0.4s, box-shadow 0.4s;
      width: 100%;

      &:hover {
        background-color: rgba($color: $color-background-dark, $alpha: 0.2);
        box-shadow: inset 0 10px 30px rgba($color: $color-background-dark, $alpha: 0.15);

        .app__tile {
          background-color: $color-green-darker;
        }

        .logo {
          transform: scale(1.2);
        }
      }

      &__panel {
        background-color: $color-white;
        height: 170px;
        padding: 25px;
        width: 100%;
      }

      &__name {
        border-bottom: 1px solid $color-grey-light;
        margin: 0;
        padding-bottom: 10px;
      }

      &__description {
        color: rgba($color: $color-background-dark, $alpha: 0.6);
        font-size: 14px;
        margin-top: 10px;
      }

      &__tile {
        @include flexCenter;
        height: 300px;
        padding: 50px;
        transition: background-color 0.6s;
        width: 100%;
      }

      .logo {
        @include flexCenter;
        background-color: #fff;
        border-radius: $radius-app;
        box-shadow: $shadow-box;
        height: 90px;
        max-width: 400px;
        padding: 15px;
        transition: transform 0.2s cubic-bezier(.29,.12,.62,2);
        width: 100%;

        &__img {
          height: 100%;
          width: auto;
        }
      }
    }
  }

  &__footer {
    @include flexCenter;
    flex-direction: column;
    padding: 10px;
    width: 100%;

    &--main {
      background-color: $color-white;
      box-shadow: $shadow-footer;
    }

    .footer {
      &__copy,
      &__issues {
        color: $color-grey;
        font-size: 14px;
        margin: 0;
        text-align: center;
      }

      &__link {
        color: $color-green-main;
        font-weight: bold;
        text-decoration: none;

        &:active, &:focus, &:hover {
          color: $color-green-dark;
        }
      }
    }
  }
}
