// Colors
$color-green-main: #3cba58;
$color-green-dark: #2f9e47;
$color-green-darker: #007b40;

$color-red-dark: #db574b;

$color-white: #ffffff;
$color-grey-light: rgb(216, 216, 216);
$color-grey: #798188;

$color-background: #f5f7f9;
$color-background-dark: #1c2a38;

// Sizes
$radius-app: 14px;
$radius-box: 6px;
$radius-input: 5px;
$landing-header-height: 80px;

// Shadows
$shadow-box:
  0 5px 10px rgba($color: $color-background-dark, $alpha: 0.1),
  0 40px 60px rgba($color: $color-background-dark, $alpha: 0.1);
$shadow-header:
  0 5px 10px rgba($color: $color-background-dark, $alpha: 0.07),
  0 20px 40px rgba($color: $color-background-dark, $alpha: 0.07);
$shadow-footer:
  0 -5px 10px rgba($color: $color-background-dark, $alpha: 0.07),
  0 -20px 40px rgba($color: $color-background-dark, $alpha: 0.07);
