@mixin flexCenter {
  align-items: center;
  display: flex;
  justify-content: center;
}

@mixin button {
  cursor: pointer;
  outline-color: #fff;
  outline-style: none;
  outline-width: 0;

  &:active {
    outline: none;
  }
}

@mixin banner($color) {
  background-color: rgba($color: $color, $alpha: 0.3);
  border-color: $color;
  border-radius: $radius-input;
  color: $color;
  font-weight: bold;
}
