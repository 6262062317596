// Labels
.control-label {
  color: rgba(79,92,101,.6);
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}

// Text inputs
input.form-control {
  border: 1px solid rgba(0,0,0,.08);
  border-radius: $radius-input;
  color: #333;
  font-weight: 600;
  margin-bottom: 16px;
  padding: 12px;
  height: 48px;

  &:hover {
    border-color: rgba(0,0,0,.2);
  }

  &:focus {
    border-color: $color-green-main;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1);
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &::placeholder {
    color: rgba(0,0,0,.25);
  }
}

// Buttons
.btn {
  @include button;
  border: 0px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 24px;
  text-align: center;
  transition: background-color 0.2s;

  &.btn-default {
    background-color: $color-green-main;
    color: $color-white;

    &:hover, &:active, &:focus {
      background-color: $color-green-dark;
      outline: none;
    }

    &:active {
      color: $color-white;
    }
  }

  &.btn-secondary {
    background: none;

    &:hover, &:active, &:focus {
      background-color: rgba($color: $color-background-dark, $alpha: 0.2);
      outline: none;
    }
  }
}

// Status banners
.banner {
  border-left: 6px solid transparent;
  padding: 7px 15px;

  &-warning {
    @include banner($color-red-dark);
  }

  &-success {
    @include banner($color-green-dark);
  }
}
